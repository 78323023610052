<template>
	<div class="edit">
		<div>
			<span>Paid was changed from "No" to "Yes"</span> by
			<span>John Doe (DEXT TECHSOFT)</span>
		</div>
		<div class="timestamp">today at 10:06</div>
	</div>
</template>
<script>
export default {
	name: "History",
};
</script>
