<template>
	<v-bottom-sheet v-model="drawer" persistent>
		<v-sheet class="text-center sheet-wrapper" height="50vh" min-height="400px">
			<v-layout class="sheet-header d-flex px-5 py-3 border-bottom">
				<v-btn tile color="blue darken-4" class="white--text mr-2"> Save </v-btn>
				<v-btn tile color="blue darken-4" class="white--text mr-2"> Bulk edit </v-btn>
				<v-btn tile color="red darken-4" class="white--text"> Delete </v-btn>
				<v-spacer></v-spacer>
				<v-tooltip top content-class="custom-top-tooltip tooltip-text">
					<template v-slot:activator="{ on, tooltip }">
						<v-icon v-on="on" v-bind="tooltip" class="mr-3" link color="blue darken-4">mdi-cog</v-icon>
					</template>
					<span>Table Setting</span>
				</v-tooltip>
				<v-tooltip top content-class="custom-top-tooltip tooltip-text">
					<template v-slot:activator="{ on, tooltip }">
						<div v-on="on" v-bind="tooltip">
							<v-btn tile v-on:click="$emit('close')">
								<v-icon style="font-size: 18px">mdi-close</v-icon>
								close
							</v-btn>
						</div>
					</template>
					<span>Close dialog</span>
				</v-tooltip>
			</v-layout>
			<div class="sheet-body mx-5">
				<div class="table-layout">
					<table class="w-100">
						<thead>
							<tr>
								<th class="d-select">
									<v-checkbox hide-details class="ma-0" v-model="checkbox"> </v-checkbox>
								</th>
								<th class="description">Description</th>
								<th class="category">Category</th>
								<th class="quantity">Quantity</th>
								<th class="unit-net-amount">Unit Price (Exc. Tax)</th>
								<th class="net-amount text-right">Net</th>
								<th class="tax-amount text-right">Tax</th>
								<th class="total-amount text-right">Total</th>
								<th class="action"></th>
							</tr>
						</thead>
						<tbody>
							<tr class="" v-for="(row, index) in line_items" :key="index">
								<td class="d-select">
									<v-checkbox hide-details class="ma-0" v-model="line_items[index].check_status">
									</v-checkbox>
								</td>
								<td class="description">
									<v-text-field hide-details outlined v-model="line_items[index].description">
									</v-text-field>
								</td>
								<td class="category">
									<v-autocomplete
										hide-details
										outlined
										:items="categoryList"
										v-model="line_items[index].category"
									>
									</v-autocomplete>
								</td>
								<td class="quantity">
									<v-text-field hide-details outlined type="number" v-model="line_items[index].quantity">
									</v-text-field>
								</td>
								<td class="unit-net-amount">
									<v-text-field
										hide-details
										outlined
										type="number"
										v-model="line_items[index].unit_net_amount"
									>
									</v-text-field>
								</td>
								<td class="net-amount">
									<v-text-field hide-details outlined type="number" v-model="line_items[index].net_amount">
									</v-text-field>
								</td>
								<td class="tax-amount">
									<v-text-field hide-details outlined type="number" v-model="line_items[index].tax_amount">
									</v-text-field>
								</td>
								<td class="total-amount">
									<v-text-field hide-details outlined type="number" v-model="line_items[index].description">
									</v-text-field>
								</td>
								<td>
									<v-icon color="red" v-on:click="deleteLineItem(index)">mdi-close</v-icon>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td colspan="4">
									<v-btn tile color="blue darken-4" class="white--text" v-on:click="addLineItem()">
										<v-icon class="mr-1">mdi-plus</v-icon>
										Add new line item
									</v-btn>
								</td>
								<td class="sum-label">
									<span class="ellipsize"><span>Line items</span></span>
								</td>
								<td class="net-amount">
									<span class="ellipsize"><span>0.00</span></span>
								</td>
								<td class="tax-amount">
									<span class="ellipsize"><span>0.00</span></span>
								</td>
								<td class="total-amount">
									<span class="ellipsize"><span>0.00</span></span>
								</td>
								<td></td>
							</tr>
							<tr>
								<td class="sum-label" colspan="5">
									<span class="ellipsize"><span>Out by</span></span>
								</td>
								<td class="net-amount out-by">
									<span class="ellipsize"><span>-84.00</span></span>
								</td>
								<td class="tax-amount">
									<span class="ellipsize"><span>0.00</span></span>
								</td>
								<td class="total-amount out-by">
									<span class="ellipsize"><span>-84.00</span></span>
								</td>
								<td></td>
							</tr>
							<tr>
								<td class="sum-label" colspan="5">
									<span class="ellipsize"><span>Item Total</span></span>
								</td>
								<td class="net-amount">
									<span class="ellipsize"><span>84.00</span></span>
								</td>
								<td class="tax-amount">
									<span class="ellipsize"><span>0.00</span></span>
								</td>
								<td class="total-amount">
									<span class="ellipsize"><span>84.00</span></span>
								</td>
								<td></td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</v-sheet>
	</v-bottom-sheet>
</template>
<script>
export default {
	name: "Add-Line-Items",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			line_items: [
				{
					check_status: false,
					description: null,
					category: null,
					quantity: null,
					unit_net_amount: null,
					net_amount: null,
					tax_amount: null,
					total_amount: null,
				},
			],
			categoryList: [],
			checkbox: false,
		};
	},
	methods: {
		addLineItem() {
			const obj = {
				check_status: false,
				description: null,
				category: null,
				quantity: null,
				unit_net_amount: null,
				net_amount: null,
				tax_amount: null,
				total_amount: null,
			};
			this.line_items.push(obj);
		},
		deleteLineItem(index) {
			this.line_items.splice(index, 1);
		},
	},
};
</script>
