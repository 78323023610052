<template>
	<div class="message-content h-100">
		<div class="inbox">
			<span class="divider-by-day"><span>Thursday, 19 May 8:59 AM</span></span>
			<div class="mine">
				<div class="message">printing and typesetting industry<span>8:59 AM</span></div>
			</div>
			<div class="mine">
				<div class="message">Lorem Ipsum is simpl<span>8:59 AM</span></div>
			</div>
			<span class="divider-by-day"><span>Thursday, 19 May 8:59 AM</span></span>
			<div class="mine">
				<div class="message">
					Lorem Ipsum is simply dummy text of the printing and typesetting industry.<span>8:59 AM</span>
				</div>
			</div>
			<div class="your">
				<div class="message">Lorem Ipsum is simply dummy text<span>8:59 AM</span></div>
			</div>
			<span class="divider-by-day"><span>Thursday, 19 May 8:59 AM</span></span>
			<div class="your">
				<div class="message">
					Lorem Ipsum is simply dummy text of the printing and typesetting industry.<span>8:59 AM</span>
				</div>
			</div>
			<div class="mine">
				<div class="message">
					Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
					the industry's standard dummy text ever since the 1500s<span>8:59 AM</span>
				</div>
			</div>
			<div class="textarea">
				<v-textarea hide-details class="ma-0" outlined rows="2"> </v-textarea>
				<v-icon class="send-btn cursor-pointer">mdi-send</v-icon>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "MessageTab",
	data() {
		return {};
	},
};
</script>
