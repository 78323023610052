<template>
	<div>
		<div class="pdf-preview-wrapper pdf-preview2 w-100">
			<v-layout class="header-top w-100">
				<v-flex class="file-name">
					<div>
						<v-icon class="red--text" style="width: 54px; font-size: 34px">mdi-file-pdf-outline</v-icon>
					</div>
					<div class="name mr-4">
						<span>NLKMi66C3WtOslfQDT1F5UPJegqM1jDF14ahLR5m.pdf</span>
						<div class="size">91KB</div>
					</div>
					<div>
						<v-tooltip bottom content-class="custom-bottom-tooltip tooltip-text">
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-on="on" v-bind="attrs" class="rename-btn ml-2" color="blue" link
									>mdi-pencil</v-icon
								>
							</template>
							<span class="">Rename</span>
						</v-tooltip>
						<v-tooltip bottom content-class="custom-bottom-tooltip tooltip-text">
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-on="on" v-bind="attrs" class="rename-btn ml-2" color="blue" link
									>mdi-lock-open-outline</v-icon
								>
							</template>
							<span class="">Lock</span>
						</v-tooltip>
					</div>
				</v-flex>
				<v-spacer></v-spacer>
				<v-flex class="file-actions d-flex justify-content-end">
					<div class="btn txt download" v-on:click="add_doc_drawer = true">
						<v-icon color="white">mdi-plus</v-icon>
						<span>Add Document</span>
					</div>
				</v-flex>
			</v-layout>
			<v-layout class="pdf-body pdf-body2">
				<v-flex class="content">
					<div class="content-wrapper">
						<div class="content-inner">
							<img src="/media/pdf/preview-img.jpg" alt="" />
						</div>
						<div class="pdf-pagination">
							<div class="left">
								<div class="btns">
									<v-tooltip top content-class="custom-top-tooltip tooltip-text">
										<template v-slot:activator="{ on, attrs }">
											<div v-on="on" v-bind="attrs" class="prev">
												<img src="/media/pdf/menu-left.svg" alt="" />
											</div>
										</template>
										<span class="">Previous Page</span>
									</v-tooltip>
									<div class="number">
										<span>1</span>
									</div>
									<v-tooltip top content-class="custom-top-tooltip tooltip-text">
										<template v-slot:activator="{ on, attrs }">
											<div v-on="on" v-bind="attrs" class="next">
												<img src="/media/pdf/menu-left.svg" alt="" />
											</div>
										</template>
										<span class="">Next Page</span>
									</v-tooltip>
								</div>
								<div class="txt">
									<span>of 1</span>
								</div>
							</div>
							<div class="right">
								<v-tooltip top content-class="custom-top-tooltip tooltip-text">
									<template v-slot:activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs" class="fit-to-width">
											<img src="/media/pdf/fit-to-width.svg" alt="" />
										</div>
									</template>
									<span class="">Fit to width</span>
								</v-tooltip>
								<v-tooltip top content-class="custom-top-tooltip tooltip-text">
									<template v-slot:activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs" class="zoom-out">
											<img src="/media/pdf/zoom-out.svg" alt="" />
										</div>
									</template>
									<span class="">Zoom out</span>
								</v-tooltip>
								<v-tooltip top content-class="custom-top-tooltip tooltip-text">
									<template v-slot:activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs" class="zoom-in">
											<img src="/media/pdf/zoom-in.svg" alt="" />
										</div>
									</template>
									<span class="">Zoom In</span>
								</v-tooltip>
							</div>
						</div>
					</div>
				</v-flex>
				<v-flex class="sidebar">
					<div class="sidebar-wrapper">
						<div class="tabs">
							<v-tabs v-model="documentTab" background-color="transparent" color="blue darken-4" grow>
								<v-tab href="#details"> Details </v-tab>
								<v-tab href="#message"> Message </v-tab>
								<v-tab href="#history"> History </v-tab>
							</v-tabs>
						</div>
						<div class="tab-content">
							<v-tabs-items v-model="documentTab">
								<v-tab-item value="details">
									<h3 class="mt-5 fw-600">Item Details</h3>
									<div class="seprater"></div>
									<div class="columns">
										<div class="d-form-grp">
											<label>Type</label>
											<v-autocomplete
												hide-details
												outlined
												:items="listType"
												v-model="detail.type"
											></v-autocomplete>
										</div>
										<div class="d-form-grp mt-4">
											<label>Date</label>
											<v-menu
												v-model="date_menu"
												:close-on-content-click="false"
												transition="scale-transition"
												offset-y
												max-width="290px"
												min-width="auto"
											>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														v-model="detail.date"
														append-icon="mdi-calendar"
														v-bind="attrs"
														hide-details
														outlined
														v-on="on"
													></v-text-field>
												</template>
												<v-date-picker
													v-model="detail.date"
													no-title
													@input="date_menu = false"
												></v-date-picker>
											</v-menu>
										</div>
										<div class="d-form-grp mt-4">
											<label>Suppliers</label>
											<v-autocomplete
												hide-details
												outlined
												:items="listType"
												v-model="detail.supplier"
											></v-autocomplete>
										</div>
										<div class="d-form-grp mt-4">
											<label>Category</label>
											<v-autocomplete
												hide-details
												outlined
												:items="listType"
												v-model="detail.category"
											></v-autocomplete>
										</div>
										<div class="d-form-grp mt-4">
											<label>Description</label>
											<v-textarea hide-details outlined rows="2" v-model="detail.description"></v-textarea>
										</div>
									</div>
									<div class="mt-8 seprater"></div>
									<h3 class="mt-5 fw-600">Amount</h3>
									<div class="columns pt-2">
										<div class="d-form-grp">
											<label>Currency</label>
											<v-autocomplete
												hide-details
												outlined
												:items="listType"
												v-model="detail.currency"
											></v-autocomplete>
										</div>
										<div class="d-form-grp mt-4">
											<label>Total Amount</label>
											<v-text-field hide-details outlined v-model="detail.total_amount"></v-text-field>
										</div>
										<div class="d-form-grp mt-4">
											<label>Tax Amount</label>
											<v-text-field hide-details outlined v-model="detail.tax_amount"></v-text-field>
										</div>
										<div class="d-form-grp mt-4">
											<label>Net Amount</label>
											<v-text-field
												hide-details
												outlined
												disabled
												v-model="detail.net_amount"
												class="disable"
											></v-text-field>
										</div>
									</div>
									<h3 class="mt-5 fw-600">Payment</h3>
									<div class="columns pt-0">
										<div class="d-form-grp">
											<label>Paid</label>
											<v-switch
												v-model="paid_status"
												hide-details
												color="orange darken-4"
												class="mt-0"
												:label="paid_status ? 'Yes' : 'No'"
											></v-switch>
										</div>
										<div class="d-form-grp mt-4">
											<label>Payment Method</label>
											<v-autocomplete
												hide-details
												outlined
												:items="listType"
												v-model="detail.payment_method"
											></v-autocomplete>
										</div>
										<div class="d-form-grp">
											<label></label>
											<div class="blue--text pt-1 cursor-pointer" v-on:click="add_payment_drawer = true">
												Add Payment Method
											</div>
										</div>
									</div>
									<div class="mt-8 seprater"></div>
									<h3 class="mt-5 fw-600">Line Items</h3>
									<div class="columns pt-2">
										<div class="empty-box">
											<v-btn tile class="white--text" color="blue darken-4" v-on:click="add_line_items = true">
												Create line items
											</v-btn>
										</div>
										<div class="table-layout">
											<table class="w-100">
												<thead>
													<tr>
														<th class="description">Description</th>
														<th class="category">Category</th>
														<th class="net-amount text-right">Net</th>
														<th class="tax-amount text-right">Tax</th>
														<th class="total-amount text-right">Total</th>
													</tr>
												</thead>
												<tbody>
													<tr class="">
														<td class="description">Digital marketing compaigns</td>
														<td class="category">
															<span>SEO</span>
														</td>
														<td class="net-amount text-right">
															<span>0.00</span>
														</td>
														<td class="tax-amount text-right">
															<span>0.00</span>
														</td>
														<td class="total-amount text-right">
															<span>0.00</span>
														</td>
													</tr>
												</tbody>
												<tfoot>
													<tr>
														<td colspan="">
															<v-btn
																tile
																color="blue darken-4"
																class="white--text"
																v-on:click="add_line_items = true"
															>
																Edit Line Item
															</v-btn>
														</td>
														<td class="sum-label">
															<span class="ellipsize"><span>Line items</span></span>
														</td>
														<td class="net-amount">
															<span class="ellipsize"><span>0.00</span></span>
														</td>
														<td class="tax-amount">
															<span class="ellipsize"><span>0.00</span></span>
														</td>
														<td class="total-amount">
															<span class="ellipsize"><span>0.00</span></span>
														</td>
														<td></td>
													</tr>
													<tr>
														<td class="sum-label" colspan="2">
															<span class="ellipsize"><span>Out by</span></span>
														</td>
														<td class="net-amount out-by">
															<span class="ellipsize"><span>-84.00</span></span>
														</td>
														<td class="tax-amount">
															<span class="ellipsize"><span>0.00</span></span>
														</td>
														<td class="total-amount out-by">
															<span class="ellipsize"><span>-84.00</span></span>
														</td>
														<td></td>
													</tr>
													<tr>
														<td class="sum-label" colspan="2">
															<span class="ellipsize"><span>Item Total</span></span>
														</td>
														<td class="net-amount">
															<span class="ellipsize"><span>84.00</span></span>
														</td>
														<td class="tax-amount">
															<span class="ellipsize"><span>0.00</span></span>
														</td>
														<td class="total-amount">
															<span class="ellipsize"><span>84.00</span></span>
														</td>
														<td></td>
													</tr>
												</tfoot>
											</table>
										</div>
									</div>
								</v-tab-item>
								<v-tab-item value="message">
									<div class="h-100">
										<MessageTab></MessageTab>
									</div>
								</v-tab-item>
								<v-tab-item value="history">
									<h3 class="mt-5 fw-600">Recent Activity</h3>
									<div class="seprater mb-5"></div>
									<div class="receipt-history">
										<History></History>
										<History></History>
										<History></History>
										<History></History>
										<History></History>
										<History></History>
									</div>
								</v-tab-item>
							</v-tabs-items>
						</div>
					</div>
				</v-flex>
			</v-layout>
		</div>
		<AddDocument :drawer="add_doc_drawer" v-on:close="add_doc_drawer = false"></AddDocument>
		<AddPayment :drawer="add_payment_drawer" v-on:close="add_payment_drawer = false"></AddPayment>
		<AddLineItems :drawer="add_line_items" v-on:close="add_line_items = false"></AddLineItems>
	</div>
</template>
<script>
import AddDocument from "@/view/pages/components/AddDocument";
import AddPayment from "@/view/pages/components/AddPayment";
import AddLineItems from "@/view/pages/components/AddLineItems";
import MessageTab from "@/view/pages/components/MessageTab";
import History from "@/view/pages/components/History";
export default {
	name: "users",
	components: {
		AddDocument,
		AddPayment,
		AddLineItems,
		MessageTab,
		History,
	},
	data() {
		return {
			date_menu: false,
			add_line_items: false,
			add_payment_drawer: false,
			add_doc_drawer: false,
			documentTab: "details",
			paid_status: true,
			listType: [],
			detail: {
				type: null,
			},
		};
	},
};
</script>
<style></style>
