<template>
	<div>
		<v-navigation-drawer
			v-model="drawer"
			fixed
			width="600"
			right
			temporary
			stateless
			style="z-index: 99"
		>
			<div class="drawer-wrapper">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header white--text orange darken-2">
					<div class="font-level-3-bold my-auto">Add Payment Method</div>
					<v-spacer></v-spacer>
					<v-btn tile color="blue darken-4" class="white--text">Save</v-btn>
					<v-btn tile color="" class="ml-2" v-on:click="$emit('close')">Cancel</v-btn>
				</v-flex>
				<div class="drawer-content pt-5 px-5">
					<div class="d-form-grp">
						<label class="mb-2">Name</label>
						<v-text-field
							hide-details
							outlined
							placeholder="Add payment method name"
							v-model="payment_method"
						></v-text-field>
					</div>
					<div class="d-form-grp mt-4">
						<label class="mb-2">Reference(optional)</label>
						<v-text-field
							hide-details
							outlined
							placeholder="add reference e.g.your card last 4 digit"
							v-model="payment_reference"
						></v-text-field>
					</div>
				</div>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
export default {
	name: "Number",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			payment_method: null,
			payment_reference: null,
		};
	},
};
</script>
