<template>
	<div>
		<v-navigation-drawer
			v-model="drawer"
			fixed
			width="600"
			right
			temporary
			stateless
			style="z-index: 99"
		>
			<div class="drawer-wrapper">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold my-auto">Add Document</div>
					<v-spacer></v-spacer>
					<v-icon v-on:click="$emit('close', true)">mdi-close</v-icon>
				</v-flex>
				<div class="drawer-content pt-5 px-5">
					<div class="tabs">
						<v-tabs v-model="documentTab" background-color="transparent" color="blue darken-4" grow>
							<v-tab href="#cost"> Costs </v-tab>
							<v-tab href="#sales"> Sales </v-tab>
							<v-tab href="#bank"> Bank </v-tab>
						</v-tabs>
					</div>
					<div class="tab-conntent">
						<v-tabs-items v-model="documentTab">
							<v-tab-item value="cost">
								<div class="pt-4">
									Use this panel to add your
									<span class="fw-500">bills, receipts and purchase invoices</span>
								</div>
								<div class="pt-4 d-flex">
									<div class="w-50">
										<div>Document owner</div>
										<span class="blue--text" style="font-size: 11px">Add new user</span>
									</div>
									<div class="w-50">
										<v-autocomplete hide-details :items="usersList" outlined v-model="users">
										</v-autocomplete>
									</div>
								</div>
								<div class="pt-4">
									<v-icon color="blue" class="mr-1">mdi-desktop-mac</v-icon>
									<span class="fw-500">Upload from computer</span>
								</div>
								<div class="pt-4">
									<v-radio-group v-model="radioGroup" class="ma-0">
										<div class="d-flex px-3">
											<div class="w-50">
												<div class="d-flex">
													<v-radio hide-details value="1" class="ma-0"></v-radio>
													<label class="fw-600">one document per file</label>
												</div>
												<span class="fw-500">PDF, JPG, PNG, ZIP</span>
											</div>
											<div class="w-50">
												<div class="d-flex">
													<v-radio hide-details value="2" class="ma-0"></v-radio>
													<label class="fw-600">one document per file</label>
												</div>
												<span class="fw-500">PDF files only</span>
											</div>
										</div>
									</v-radio-group>
								</div>
								<div class="pt-4">
									<div class="drag-and-drop-documents">
										<div class="upload-button">
											<p><span class="button">Select Files</span></p>
											<p class="separator">or</p>
											<p class="title">Drag &amp; drop to upload</p>
											<div class="upload-validations-file-sizes">
												<h4>File limits</h4>
												<span> |&nbsp;6MB for images, 100MB for ZIP files</span>
											</div>
										</div>
									</div>
								</div>
							</v-tab-item>
							<v-tab-item value="sales">
								<div class="pt-4">
									Use this panel to add your
									<span class="fw-500">sales invoices</span>
								</div>
								<div class="pt-4 d-flex">
									<div class="w-50">
										<div>Document owner</div>
										<span class="blue--text" style="font-size: 11px">Add new user</span>
									</div>
									<div class="w-50">
										<v-autocomplete hide-details :items="usersList" outlined v-model="users">
										</v-autocomplete>
									</div>
								</div>
								<div class="pt-4">
									<v-icon color="blue" class="mr-1">mdi-desktop-mac</v-icon>
									<span class="fw-500">Upload from computer</span>
								</div>
								<div class="pt-4">
									<v-radio-group v-model="radioGroup" class="ma-0">
										<div class="d-flex px-3">
											<div class="w-50">
												<div class="d-flex">
													<v-radio hide-details value="1" class="ma-0"></v-radio>
													<label class="fw-600">one document per file</label>
												</div>
												<span class="fw-500">PDF, JPG, PNG, ZIP</span>
											</div>
											<div class="w-50">
												<div class="d-flex">
													<v-radio hide-details value="2" class="ma-0"></v-radio>
													<label class="fw-600">one document per file</label>
												</div>
												<span class="fw-500">PDF files only</span>
											</div>
										</div>
									</v-radio-group>
								</div>
								<div class="pt-4">
									<div class="drag-and-drop-documents">
										<div class="upload-button">
											<p><span class="button">Select Files</span></p>
											<p class="separator">or</p>
											<p class="title">Drag &amp; drop to upload</p>
											<div class="upload-validations-file-sizes">
												<h4>File limits</h4>
												<span> |&nbsp;6MB for images, 100MB for ZIP files</span>
											</div>
											<div class="upload-validations-file-sizes">
												<h4>File limits</h4>
												<span> |&nbsp;6MB for images, 100MB for ZIP files</span>
											</div>
											<div class="upload-validations-file-sizes">
												<h4>File limits</h4>
												<span> |&nbsp;40MB max per file, up to 50 pages</span>
											</div>
										</div>
									</div>
								</div>
							</v-tab-item>
							<v-tab-item value="bank">
								<div class="pt-4">
									Use this panel to add your
									<span class="fw-500">bank statements</span>
								</div>
								<div class="pt-4">
									<v-icon color="blue" class="mr-1">mdi-desktop-mac</v-icon>
									<span class="fw-500">Upload from computer</span>
								</div>
								<div class="pt-4">
									<div class="d-flex">
										<div class="w-50 my-auto">
											<div>Bank account</div>
										</div>
										<div class="w-50">
											<v-autocomplete hide-details :items="usersList" outlined v-model="users">
											</v-autocomplete>
										</div>
									</div>
									<div class="mt-2">
										Can't see your bank account listed? <span class="blue--text"> Add a bank account</span>
									</div>
								</div>
								<div class="pt-4">
									<div>
										<div class="fw-600">One document per file</div>
										<span class="fw-500">PDF, TIFF, maximum A1 page size</span>
									</div>
								</div>
								<div class="pt-4">
									<div class="drag-and-drop-documents">
										<div class="upload-button">
											<p><span class="button">Select Files</span></p>
											<p class="separator">or</p>
											<p class="title">Drag &amp; drop to upload</p>
											<div class="upload-validations-file-sizes">
												<h4>File limits</h4>
												<span> |&nbsp;6MB for images, 100MB for ZIP files</span>
											</div>
										</div>
									</div>
								</div>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</div>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
export default {
	name: "Number",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			documentTab: "cost",
			radioGroup: null,
			usersList: [],
			users: null,
		};
	},
};
</script>
